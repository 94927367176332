* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* iframe {
  display: none;
} */

.loginScreenBg {
  position: absolute;
  background-color: #080710;
  width: 100%;
  height: 100vh;
}

.loginScreen .background {
  width: 430px;
  height: 520px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.loginScreen .background .shape {
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
}
.loginScreen .shape:first-child {
  background: linear-gradient(#1845ad, #23a2f6);
  left: -80px;
  top: -80px;
}
.loginScreen .shape:last-child {
  background: linear-gradient(to right, #ff512f, #f09819);
  right: -30px;
  bottom: -80px;
}
.loginScreen form {
  height: 520px;
  width: 400px;
  background-color: rgba(255, 255, 255, 0.13);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  padding: 50px 35px;
}
.loginScreen form * {
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  letter-spacing: 0.5px;
  outline: none;
  border: none;
}
.loginScreen form h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
}

.loginScreen label {
  display: block;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
}
.loginScreen input {
  display: block;
  height: 50px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
}
.loginScreen ::placeholder {
  color: #e5e5e5;
}
.loginScreen button {
  margin-top: 50px;
  width: 100%;
  background-color: #ffffff;
  color: #080710;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

body {
  /* background-image: url("bgImage3.jpeg");
  background-size: cover; */
  font-family: "Gill Sans", sans-serif;
}

@keyframes input-border {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
  100% {
    border-color: transparent;
  }
}

.homeScreen {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.3));
  position: absolute;
  top: 40%;
  left: 25%;
  transform: translate(-50%, -50%);
  padding: 30px;
  border-radius: 10px;
  /* opacity: 0.3; */
  width: 25%;
}

.homeScreenMobile {
  /* background: linear-gradient(to right, white, black); */
  background: linear-gradient(to bottom, white, black);
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  border-radius: 10px;
  opacity: 0.3;
  width: 60%;
}

.viewImg {
  height: 320px;
  width: 100%;
  border-radius: 5%;
}

.denemeImg {
  border-radius: 5%;
}

.homeScreen1 {
  background: linear-gradient(to bottom, white, black);
  position: absolute;
  top: 83%;
  left: 25%;
  transform: translate(-50%, -50%);
  padding: 20px 30px;
  border-radius: 10px;
  opacity: 0.5;
  margin-top: 10px;
  width: 25%;
}

.homeScreen1Mobile {
  background: linear-gradient(to bottom, white, black);
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 30px;
  border-radius: 10px;
  opacity: 0.5;
  margin-top: 10px;
  width: 60%;
}

.homeScreen2 {
  position: absolute;
  top: 50%;
  left: 47%;
  transform: translate(-50%, -50%);
  padding: 120px;
  border-radius: 10px;
}

.homeScreen2Mobile {
  position: absolute;
  top: 125%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 120px;
  border-radius: 10px;
}

.homeScreen3 {
  border: 3px solid gray;
  position: absolute;
  top: 45%;
  left: 71%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  opacity: 0.7;
  height: 70vh;
  width: 30%;
}

.homeScreen3Mobile {
  border: 3px solid gray;
  position: absolute;
  top: 180%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  opacity: 0.7;
  height: 70vh;
  width: 60%;
}

.input-animated {
  display: block;
  margin-bottom: 30px;
  margin-top: 10px;
  border: 2px solid transparent;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 18px;
  animation: input-border 2s infinite;
  height: 10vh;
  color: gray;
  width: 100%;
  height: 130px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.containerMobile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-input {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  /* animation: pulse 2s infinite; */
}

.upload-input input[type="file"] {
  position: absolute;
  left: -9999px;
}

.upload-label {
  padding: 12px 0px;
  /* background-color: white; */
  color: white;
  font-size: 1.2rem;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  text-align: center;
}

.upload-labelMobile {
  /* padding: 2px 4px; */
  /* background-color: white; */
  color: white;
  font-size: 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

/* .upload-label:hover {
    font-size: 20px;
    transition: font-size 1.5s ease;
} */

.labelImg {
  text-align: center;
}

.br-20 {
  border-radius: 20px;
}

.wrapper {
  margin-left: 7%;
  cursor: pointer;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrapper .link_wrapper {
  position: relative;
}

.wrapper button {
  display: block;
  width: 150px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  text-decoration: none;
  background: #333;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 3px solid #333;
  transition: all 0.35s;
}

.wrapper .icon {
  width: 50px;
  height: 50px;
  border: 3px solid transparent;
  position: absolute;
  transform: rotate(45deg);
  right: 0;
  top: 0;
  z-index: -1;
  transition: all 0.35s;
}

.wrapper .icon svg {
  width: 30px;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  transform: rotate(-45deg);
  fill: white;
  transition: all 0.35s;
}

.wrapper button:hover {
  margin-right: 20px;
  width: 180px;
  border: 3px solid white;
  background: transparent;
  color: white;
}
.wrapper button:hover + .icon {
  margin-right: 5px;
  border: 3px solid white;
  right: -25%;
}

.homeScreenMenu {
  /* background: linear-gradient(to right, white, black); */
  /* background: linear-gradient(to bottom, white, black); */
  position: absolute;
  top: 3%;
  left: 1%;
  /* transform: translate(-50%, -50%);
  padding: 5px; */
  border-radius: 10px;
  /* opacity: 0.3; */
}

.containerMenu {
  position: relative;
  display: inline-block;
}

.menuIcon {
  position:relative;
  top:2px;
}

.buttonMenu {
  margin-left: 100%;
  background-color: transparent;
  color: white;
  border: 2px solid white;
  padding: 12px;
  cursor: pointer;
  border-radius: 100%;
  font-size: 20px;
  transition: border-radius 0.5s, background-color 0.5s, color 0.5s;
}

.containerMenu:hover .buttonMenu {
  /* border-radius: 0; */
  background-color: white;
  color: black;
}

.menu {
  display: none;
  position: absolute;
  /* border: 2px white solid; */
  border-radius: 10px;
  /* margin-top: 20px; */
  /* background-color: #f9f9f9; */
  min-width: 140px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 15px;
}

.menu a {
  background-image: linear-gradient(to right, orange, green);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 30px;
  /* padding: 8px 16px; */
  display: block;
  opacity: 0;
  text-decoration: none;
  transform: translateX(-100%);
  animation-name: slideIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.menu a:hover {
  transition: font-size 0.5s ease;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.containerMenu:hover .menu {
  display: block;
}

.containerMenu:hover .menu a {
  animation-delay: calc(0.5s * (var(--index) + 1));
}

.menu a:nth-child(1) {
  --index: 0;
}

.menu a:nth-child(2) {
  --index: 1;
}

.menu a:nth-child(3) {
  --index: 2;
}

.menu a:nth-child(4) {
  --index: 3;
}

.menu a:nth-child(5) {
  --index: 4;
}

.menu a:nth-child(6) {
  --index: 5;
}

.menu a:nth-child(7) {
  --index: 6;
}

.homeScreenProfileMenu {
  position: absolute;
  top: 2%;
  right: 5%;
  border-radius: 10px;
  z-index: 2;
}

.homeScreenProfileMenuMobile {
  /* background: linear-gradient(to right, white, black); */
  /* background: linear-gradient(to bottom, white, black); */
  position: absolute;
  top: 2%;
  /* left: 80%; */
  right: 50px;
  /* transform: translate(-50%, -50%);
  padding: 5px; */
  border-radius: 10px;
  /* opacity: 0.3; */
}

.containerProfileMenu {
  position: relative;
  display: inline-block;
}

.buttonProfileMenu {
  margin-top: 10px;
  background-color: transparent;
  color: white;
  border: none;
  padding: 12px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: url('profile.png'); /* Profil fotoğrafı URL'si */
  background-size: cover;
  background-position: center;
  margin-bottom: 8px;
  animation: rotateLeftToRight 5s linear infinite;
}

@keyframes rotateLeftToRight {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.buttonProfileMenuMobile {
  margin-left: 40%;
  background-color: transparent;
  color: white;
  border: none;
  /* border: 2px solid red;
  animation: border-animation 5s infinite; */
  padding: 12px;
  cursor: pointer;
  border-radius: 10px;
  /* font-size: 20px; */
  /* transition: border-radius 0.5s, background-color 0.5s, color 0.5s; */
}

/* @keyframes border-animation {
  0% {
    border-color: red;
  }

  25% {
    border-color: green;
  }

  50% {
    border-color: blue;
  }

  75% {
    border-color: orange;
  }

  100% {
    border-color: white;
  }
} */

.menuProfile {
  display: none;
  position: absolute;
  border-radius: 10px;
  background: linear-gradient(to bottom, white, gray);
  min-width: 130px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 15px;
  left: -75%;
}

.menuProfileMobile {
  display: none;
  position: absolute;
  border-radius: 10px;
  background: linear-gradient(to bottom, white, gray);
  min-width: 130px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 15px;
}

.menuProfile a {
  background-image: linear-gradient(to right, blue, orange);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 30px;
  /* padding: 8px 16px; */
  display: block;
  opacity: 0;
  text-decoration: none;
  transform: translateX(100%);
  animation-name: slideInProfile;
  animation-duration: 0.5s;
  animation-fill-mode:forwards;
}

.menuProfileMobile a {
  background-image: linear-gradient(to right, blue, orange);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 30px;
  /* padding: 8px 16px; */
  display: block;
  opacity: 0;
  text-decoration: none;
  transform: translateX(100%);
  animation-name: slideInProfile;
  animation-duration: 0.5s;
  animation-fill-mode:forwards;
}

.menuProfile a:hover {
  transition: font-size 0.5s ease;
}

.menuProfileMobile a:hover {
  transition: font-size 0.5s ease;
}

@keyframes slideInProfile {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.containerProfileMenu:hover .menuProfile {
  display: block;
}

.containerProfileMenuMobile:hover .menuProfileMobile {
  display: block;
}

.containerProfileMenu:hover .menuProfile a {
  animation-delay: calc(0.5s * (var(--index) + 1));
}

.containerProfileMenuMobile:hover .menuProfileMobile a {
  animation-delay: calc(0.5s * (var(--index) + 1));
}

.menuProfile a:nth-child(1) {
  --index: 0;
}

.menuProfile a:nth-child(2) {
  --index: 1;
}

.menuProfile a:nth-child(3) {
  --index: 2;
}

.menuProfile a:nth-child(4) {
  --index: 3;
}

.menuProfileMobile a:nth-child(1) {
  --index: 0;
}

.menuProfileMobile a:nth-child(2) {
  --index: 1;
}

.menuProfileMobile a:nth-child(3) {
  --index: 2;
}

.menuProfileMobile a:nth-child(4) {
  --index: 3;
}

.containerbtn {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 50px auto;
  border-radius: 50%;
  /* background-color: #f1f1f1; */
}

.ai-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  border: none;
}

.button-with-image {
  /* background-image: url('btn-img.jpeg'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.sub-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 100px 0;
  width: 40px;
  height: 40px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  opacity: 0;
  transform: translate(-50%, -50%) rotate(0deg) translate(100px) rotate(0deg);
  transition: opacity 0.01s ease-in-out;
}

.containerbtn:hover .sub-button {
  opacity: 1;
}

.containerbtn:hover .sub-button:nth-child(1) {
  transform: translate(-50%, -50%) rotate(0deg) translate(100px) rotate(0deg);
  transition-delay: 0.1s;
}

.containerbtn:hover .sub-button:nth-child(2) {
  transform: translate(-50%, -50%) rotate(72deg) translate(100px) rotate(-72deg);
  transition-delay: 0.2s;
}

.containerbtn:hover .sub-button:nth-child(3) {
  transform: translate(-50%, -50%) rotate(144deg) translate(100px) rotate(-144deg);
  transition-delay: 0.3s;
}

.containerbtn:hover .sub-button:nth-child(4) {
  transform: translate(-50%, -50%) rotate(216deg) translate(100px) rotate(-216deg);
  transition-delay: 0.4s;
}

.containerbtn:hover .sub-button:nth-child(5) {
  transform: translate(-50%, -50%) rotate(288deg) translate(100px) rotate(-288deg);
  transition-delay: 0.5s;
}

/* .containerbtn:hover .sub-button:nth-child(6) {
  transform: translate(-50%, -50%) rotate(150deg) translate(100px) rotate(-150deg);
  transition-delay: 0.6s;
}

.containerbtn:hover .sub-button:nth-child(7) {
  transform: translate(-50%, -50%) rotate(180deg) translate(100px) rotate(-180deg);
  transition-delay: 0.7s;
}

.containerbtn:hover .sub-button:nth-child(8) {
  transform: translate(-50%, -50%) rotate(210deg) translate(100px) rotate(-210deg);
  transition-delay: 0.8s;
}

.containerbtn:hover .sub-button:nth-child(9) {
  transform: translate(-50%, -50%) rotate(240deg) translate(100px) rotate(-240deg);
  transition-delay: 0.9s;
}

.containerbtn:hover .sub-button:nth-child(10) {
  transform: translate(-50%, -50%) rotate(270deg) translate(100px) rotate(-270deg);
  transition-delay: 1s;
}

.containerbtn:hover .sub-button:nth-child(11) {
  transform: translate(-50%, -50%) rotate(300deg) translate(100px) rotate(-300deg);
  transition-delay: 1.1s;
}

.containerbtn:hover .sub-button:nth-child(12) {
  transform: translate(-50%, -50%) rotate(330deg) translate(100px) rotate(-330deg);
  transition-delay: 1.2s;
} */

.homeScreen4 {
  position: absolute;
  bottom: 6%;
  right: 8%;
  width: 400px;
  height: 100px;
}

.background-div {
  background-image: url('stars.jpg'); /* Resmin dosya yolunu belirtin */
  background-position: center center; /* Resmi dikey ve yatay olarak ortalar */
  width: 100%; /* Div'in tam genişlikte olmasını sağlar */
  color: white; /* Yazı rengini ayarlayabilirsiniz */
  text-align: center; /* İçerik merkezi hizalanır */
  padding: 20px; /* İçerik ve kenar boşluğu ekler */
}

.centered-div-container {
  margin-top:50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-div {
  width: 80%;
  height: 50vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.fallowButton{
  border: none;
  background-color: transparent;
  color: #ff7844;
  font-size: 15px;
  border-left: 2px solid;
  border-top: 2px solid;
  padding: 3px 10px;
  margin-left: 20px;
}

.fallowButton:hover{
  border: none;
  background-color: transparent;
  color: #ff7844;
  font-size: 15px;
  border-right-style: solid;
  border-right-color: #ff7844;
  border-right-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #ff7844;
  border-bottom-width: 2px;
}

.tryModelBtn {
  border: 1px solid white;
  background-color: transparent;
  color: white;
  animation: try-model-animation 2s infinite;
  width: 300px;
  height: 50px;
  font-size: 20px;
  border-radius: 20px;
  transition: 'background 0.3s',
}

.tryModelBtn:hover {
  background: linear-gradient(to right, gray, transparent);
  color: white !important;
  border: none;
}

.vertical-text {
  writing-mode: vertical-rl; 
  text-orientation: mixed; 
  white-space: nowrap; 
  transform: rotate(180deg); 
  position: fixed;
  left: 35px;
  border: 1px solid white;
  /* background-color: black; */
  color: white;
  font-weight: 600;
  font-size: 20px;
  padding:20px 10px;
  border-radius: 20px;
  animation: try-model-animation 2s infinite;
  z-index:10;
  background-color: transparent;
  transition: 'background 0.3s',
}

.vertical-button {
  width: 40px; 
  height: 100px; 
  padding: 10px;
}

.vertical-text:hover {
  background: linear-gradient(to bottom, gray, transparent);
  color: white !important;
  border: none;
}

@keyframes try-model-animation {
  0% {
    border-color: white;
  }
  25% {
    border-color: #99FFCC;
  }
  40% {
    border-color: #FFCCCC;
  }
  55% {
    border-color: #FF3333;
  }
  75% {
    border-color: #99CCFF;
  }
  100% {
    border-color: #FFFF99;
  }
}

.podium-card {
  background-color:red;
  border:none;
  background: linear-gradient(to bottom, black, gray);
  color: white;
}

.gradient-text {
  background: linear-gradient(to right, #655555, white); /* Gradient renkleri burada ayarlanabilir */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}